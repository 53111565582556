<template>
	<div style="width: 100%; min-width: 1200px;" id="out">
		<div style="margin: auto;" id="out">
			<a-modal v-model="eblockSeterShow" :title="eblock" @ok="confirmSetting" cancelText="取消" :keyboard="keyboard" :maskClosable="keyboard" okText="确认" width="320px">
				<a-form-model layout="inline" :model="dischargeDepth">
					<a-form-model-item label="放电下限：">
						<a-input-number v-model="dischargeDepth.dischargeDepth_L" :min="0" :max="100" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" @change="onChange" />
					</a-form-model-item>
					<a-form-model-item label="放电上限：">
						<a-input-number v-model="dischargeDepth.dischargeDepth_U" :min="0" :max="100" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" @change="onChange" />
					</a-form-model-item>
				</a-form-model>
		    </a-modal>
		    <a-drawer :title="eLink.name" :placement="placement" :closable="false" :visible="elinkDataShow" @close="onClose" >
		      	<div>
			      	<a-row>
		       			<div class="elink_data_name" style="padding-left: 0 !important;">
			     			<span>实时功率:</span>
			     		</div>
						<div class="elink-data" style="width: calc(100%-120px);">
			     			--
			     		</div>
		       		</a-row>
		      	</div>
		      	<div>
			      	<a-row>
		       			<div class="elink_data_name" style="padding-left: 0 !important;">
			     			<span>实时能量：</span>
			     		</div>
						<div class="elink-data" style="width: calc(100%-120px);">
			     			--
			     		</div>
		       		</a-row>
		      	</div>
		      	<div>
			      	<a-row>
		       			<div class="elink_data_name" style="padding-left: 0 !important;">
			     			<span>最大可充电功率：</span>
			     		</div>
						<div class="elink-data" style="width: calc(100%-120px);">
			     			--
			     		</div>
		       		</a-row>
		      	</div>
		      	<div>
			      	<a-row>
		       			<div class="elink_data_name" style="padding-left: 0 !important;">
			     			<span>最大可放电功率：</span>
			     		</div>
						<div class="elink-data" style="width: calc(100%-120px);">
			     			--
			     		</div>
		       		</a-row>
		      	</div>
		      	<div>
			      	<a-row>
		       			<div class="elink_data_name" style="padding-left: 0 !important;">
			     			<span>接触器状态：</span>
			     		</div>
						<div class="elink-data" style="width: calc(100%-120px);">
			     			--
			     		</div>
		       		</a-row>
		      	</div>
		      	<div>
			      	<a-row>
		       			<div class="elink_data_name" style="padding-left: 0 !important;">
			     			<span>断路器状态：</span>
			     		</div>
						<div class="elink-data" style="width: calc(100%-120px);">
			     			--
			     		</div>
		       		</a-row>
		      	</div>
		      	<div>
			      	<a-row>
		       			<div class="elink_data_name" style="padding-left: 0 !important;">
			     			<span>消防反馈状态：</span>
			     		</div>
						<div class="elink-data" style="width: calc(100%-120px);">
			     			--
			     		</div>
		       		</a-row>
		      	</div>
		    </a-drawer>
			<div id="root">
				<div class="chain_station">
					<div class="chain_title" style="width: calc(100% - 20px);">
						<div style="float: left;">{{ds.name}}</div>
						<div style="float: left; margin-left: calc(100% - 100px);">
							<a-popover placement="bottom" trigger="click">
						        <template slot="content">
						          	<p style="cursor: pointer;" @click="(e)=>toDetial(e,ds,1)">监视</p>
						          	<p style="cursor: pointer;">设置</p>
						        </template>
						        <a-button style="width: 32px; padding: 0; background: transparent; border: none;">
						        	<img src="@a/images/icon/ic_more.png">
						        </a-button>
					      	</a-popover>
						</div>
					</div>
					<div class="chain_img">
						<img src="@a/images/img_energystation.png" style="cursor: pointer;" @click="(e)=>toDetial(e,ds,1)">
					</div>
				</div>
				<div class="vertical-line-down" v-show="ds.children.length > 0"></div>
				<div class="chain_childrens" style="margin: auto; width: calc(100% - 20px);" id="eLinks">
					<div class="horizontal-line" v-show="ds.children.length > 1" style="width: calc(100% - 190px);"></div>
					<div class="child float-left" v-for="(x, index_x) in ds.children" :key="index_x + '-1'" :id="'node' + x.id">
						<div class="vertical-line-up"></div>
						<div class="chain_link">
							<div class="chain_title" style="width: calc(100% - 20px);">
								<div style="float: left;">{{x.num}}#能量链</div>
								<div style="float: left; margin-left: calc(100% - 102px);">
									<a-popover placement="bottom" trigger="click">
								        <template slot="content">
								          	<p style="cursor: pointer;" @click="(e)=>toDetial(e,x,1)">监视</p>
								          	<p style="cursor: pointer;" @click="(e)=>toDetial(e,x,2)">设置</p>
								        </template>
								        <a-button style="width: 32px; padding: 0; background: transparent; border: none;">
								        	<img src="@a/images/icon/ic_more.png">
								        </a-button>
							      	</a-popover>
								</div>
							</div>
							<div class="chain_img">
								<img src="@a/images/img_elink.png" style="cursor: pointer;" @click="(e)=>toDetial(e,x,1)">
							</div>
							<div>
								<div style="padding: 0 16px;">
									<span><img src="@a/images/icon/ic_status.png" /></span>
									<span class="td_name"> 状态：</span>
									<span class="td_data" :id="'state' + x.id">
										离线
									</span>
								</div>
								<div style="padding: 0 16px;">
									<span><img src="@a/images/icon/ic_power.png" /></span>
									<span class="td_name"> 功率：</span>
									<span class="td_data" :id="'power' + x.id">
										0kW
									</span>
								</div>
								<div style="padding: 0 16px;">
									<span><img src="@a/images/icon/ic_pressure.png" /></span>
									<span class="td_name"> 电压：</span>
									<span class="td_data" :id="'voltage' + x.id">
										0V
									</span>
								</div>
								<div style="padding: 0 16px 10px 16px">
									<span><img src="@a/images/icon/ic_current.png" /></span>
									<span class="td_name"> 电流：</span>
									<span class="td_data" :id="'current' + x.id">
										0A
									</span>
								</div>
								<!--<div style="padding: 0 16px 16px 16px;">
									<span><img src="@a/images/icon/ic_status.png" /></span>
									<span class="td_name"> SOC：</span>
									<span class="td_data" :id="'soc' + x.id">
										0%
									</span>
								</div>-->
							</div>
							<div style="width: calc(100% - 32px); height: 2px; background: #23232D; padding: 0 16px "></div>
							<div class="control">
								<div style="float: left;">
									<span>开&nbsp;&nbsp;关</span> 
									<span>
										<a-switch checked-children="开" un-checked-children="关" v-model:checked="x.state > 1" size="small" style="margin-top: -3px;" :disabled="x.stateDisabled" :loading="x.loading" @change="showConfirm(x)">
									    </a-switch>
									</span>
								</div>
								<div style="float: right;">
									<span>断路器</span> <span><a-switch disabled v-model:checked="checked1"  size="small" style="margin-top: -3px;"/></span>
								</div>
							</div>
						</div>
						<div style="padding-left: 73px;">
							<span>
								<a-icon thmem="filled" type="plus-circle" v-show="x.id != expandId" style="font-size: 160%; color: #E2E2E2;" @click="(e)=>handNode(e,x)"/>
								<a-icon thmem="filled" type="minus-circle" v-show="x.id == expandId" style="font-size: 160%; color: #E2E2E2;" @click="(e)=>handNode(e,x)"/>
							</span>
						</div>
						<div class="vertical-line-down" v-show="x.children.length > 0 && x.open && x.id == expandId && expand" :id="x.id"></div>
					</div>
				</div>
			</div>
			<div class="float-left" v-show="expand" id="leafs">
				<!--<div class="chain_childrens">-->
					<div class="horizontal-line" v-show="data.length > 0" style="width: calc(100% - 190px);"></div>
					<div class="child float-left" v-for="(y, index_y) in data" :key="index_y + '-1'" :id="'node' + y.id">
						<div class="vertical-line-up"></div>
						<div class="chain_link">
							<div class="chain_title" style="width: calc(100% - 20px);">
								<div style="float: left;">{{y.num}}#能量块</div>
								<div style="float: right; ">
									<a-popover placement="bottom" trigger="click">
								        <template slot="content">
								          	<p style="cursor: pointer;" @click="(e)=>toDetial(e,y,1)">监视</p>
								          	<p style="cursor: pointer;" @click="(e)=>toDetial(e,y,2)">设置</p>
								        </template>
								        <a-button style="width: 32px; padding: 0; background: transparent; border: none;">
								        	<img src="@a/images/icon/ic_more.png">
								        </a-button>
							      	</a-popover>
								</div>
							</div>
							<div class="leaf_img">
								<img src="@a/images/system-diagram/img_eblock.png" @click="(e)=>toDetial(e,y,1)" style="cursor: pointer;">
							</div>
							<div>
								<div style="padding: 0 16px;">
									<span><img src="@a/images/icon/ic_status.png" /></span>
									<span class="td_name"> 状态：</span>
									<span class="td_data" :id="'state' + y.id">
										离线
									</span>
								</div>
								<div style="padding: 0 16px;">
									<span><img src="@a/images/icon/ic_power.png" /></span>
									<span class="td_name"> 功率：</span>
									<span class="td_data" :id="'power' + y.id">
										0kW
									</span>
								</div>
								<div style="padding: 0 16px;">
									<span><img src="@a/images/icon/ic_pressure.png" /></span>
									<span class="td_name"> 电压：</span>
									<span class="td_data" :id="'voltage' + y.id">
										0V
									</span>
								</div>
								<div style="padding: 0 16px;">
									<span><img src="@a/images/icon/ic_current.png" /></span>
									<span class="td_name"> 电流：</span>
									<span class="td_data" :id="'current' + y.id">
										0A
									</span>
								</div>
								<div style="padding: 0 16px 10px 16px;">
									<span><img src="@a/images/icon/ic_current_soc.png" /></span>
									<span class="td_name"> SOC：</span>
									<span class="td_data" :id="'soc' + y.id">
										0%
									</span>
								</div>
							</div>
							<div style="width: calc(100% - 32px); height: 2px; background: #23232D; padding: 0 16px "></div>
							<div  class="control">
								<div style="float: left;">
									<span>开&nbsp;&nbsp;关</span> 
									<span>
										<a-switch checked-children="开" un-checked-children="关" v-model:checked="y.state > 1" size="small" style="margin-top: -3px;" :loading="y.loading" :disabled="y.stateDisabled" @change="showConfirm(y)">
									    </a-switch>
									</span>
								</div>
								<div style="float: right;">
									<span>断路器</span> <span><a-switch disabled v-model:checked="checked1"  size="small" style="margin-top: -3px;"/></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			<!--</div>-->
		</div>
		<a-modal v-model="switchConfirmShow" :footer="null" :closable="false" :keyboard="false" :maskClosable="false">
	     	<div style="height: 30px;">
	     		<span><a-icon type="exclamation-circle" style="color: #FDD88F; fontSize: 16px;"/></span>
	     		<span style=" font-size: 16px; margin-left: 10px;">重要提示：</span>
	     	</div>
	     	<div style="padding-left: 30px; height: 50px;">{{title}}</div>
	     	<div style="margin-bottom: 10px;  height: 30px;">
				<a-form-model layout="inline" style="width: 100%;">
					<a-form-model-item style="float: right;">
						<a-button type="primary" @click="openOrClose">确认</a-button>
					</a-form-model-item>
					<a-form-model-item style="float: right;">
						<a-button @click="closeSwitchConfirmShow">取消</a-button>
					</a-form-model-item>
				</a-form-model>
			</div>
	    </a-modal>
	</div>
</template>

<script>
	import axios from 'axios';
	import $ from 'jquery';
	export default {
		name: 'scada-tree',
		data() {
			return {
				title: '请确认是否继续刷新配置操作？',
				eblock: '',
				eLink: {},
				placement: 'right',
				switchConfirmShow: false,
				elinkDataShow: false,
				dischargeDepth:{
					dischargeDepth_U: 100,
					dischargeDepth_L: 0
				},
				keyboard: false,
				eblockSeterShow: false,
				checked1: true,
  				checked2: false,
				expand: true,
				expandId: null,
				expandNode: null,
				data: [],
				equipNode: null,
				ds: {
					'id': 1,
					'name': '储能电站',
					'open': true,
					'children': []
				},
				timer_4: null,
			}
		},
		mounted() {
			var token = localStorage.getItem("currentUser_token");
			if ( !token && typeof(token)!="undefined" && token!=0){
			   	this.$router.replace({
					path: '/'
				})
				return;
			}
			var id = localStorage.getItem('stationId');
			if ( !id && typeof(id)!="undefined" && id!=0){
			   	this.$router.replace({
					path: '/distribution'
				})
			}
			var this_ = this;
			this_.getEquipmentTreeFn();
			this_.$erd.listenTo(document.getElementById("out"), (element)=>{
				if(this_.$route.path === '/energy_chain'){
					this_.reflush(true);
				}

          	})
			window.onresize = () => {
				if(this_.$route.path === '/energy_chain'){
					this_.reflush(true);
				}
			}
		},
		destroyed() {
			clearInterval(this.timer_4);
		},
		methods: {
			showConfirm(equipNode){
				this.title = '请再次确认是否继续' + (equipNode.state > 1 ? '关闭' : '打开') + '设备：' + equipNode.num + (equipNode.state == 2 ? '能量链？' : '能量块？');
				if(equipNode.state > 1){
					this.title = this.title + ''	
				}
				this.switchConfirmShow = true;
				this.equipNode = equipNode;
			},
			closeSwitchConfirmShow(){
				this.switchConfirmShow = false;
			},
			openOrClose(){
				clearInterval(this.timer_4);
				var this_ = this;
				this.ds.children.onoff ? 2 : 1;
				var data = {
						paramName: "onoff",
						data: this_.equipNode.state > 1 ? 1 : 2
				}
				axios({
					method: 'post',
					url: '/emind/config/equipment/system/param/' + this_.equipNode.id,
					data: data
				}).then(function(d) { //
					if(!d){
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					if(d.data.code === '1') {
						this_.$message.success(this_.equipNode.num + (this_.equipNode.state == 2 ? '能量链？' : '能量块？') + (this_.equipNode.state > 1 ? '关机' : '开机') + '命令下发成功！');
						this_.equipNode.stateDisabled = true;
						this_.equipNode.state = -1;
						this_.switchConfirmShow = false;
					} else if(d.data.code === '401'){
						this_.$message.warning('当前账户没有开关机操作权限');
						this_.switchConfirmShow = false;
					} else {
						this_.$message.error(this_.equipNode.num + (this_.equipNode.state == 2 ? '能量链？' : '能量块？') + (this_.equipNode.state > 1 ? '关机' : '开机') + '命令下发失败！');
						this_.switchConfirmShow = false;
					}
					this_.timer_4 = window.setInterval(function() {
							this_.reflushData();
					}, 3000);
				}).catch(function(error) {
					this_.switchConfirmShow = false;
					this_.timer_4 = window.setInterval(function() {
						this_.reflushData();
					}, 3000);
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});

			},
			showDrawer() {
		      this.elinkDataShow = true;
		    },
		    onClose() {
		      this.elinkDataShow = false;
		    },
			onChange(){
				
			},
			confirmSetting(){
				
			},
			setstate(data){
				var this_ = this;
				if(data.type == 2){
					this_.ds.children.map(function(item){
						if(item.id == data.id){
							if(data.power == 0){
								if(data.state == 1){
									item.state = 2;
									item.stateDisabled = false;
								} else {
									item.state = -1;
									item.stateDisabled = true;
								}
								
							} else {
								item.state = 2;
								item.stateDisabled = false;
							}
							item.loading = false;
							item.stateDisabled = true;
						}
					});
				} else if(data.type == 3){
					this_.ds.children.map(function(item){
						if(item.id == this_.expandId){
							item.children.map(function(x){
								if(data.id == x.id){
									x.state = data.state;
									x.loading = false;
									if(data.state == -1){
										x.stateDisabled = true;
									} else {
										x.stateDisabled = false;
									}
								}
							});						
						}
					});
				}
			},
			reflushData() {
				var this_ = this;
				var ids = '';
				for(var i = 0; i < this_.data.length; i++) {
					ids = ids + this_.data[i].id + ','
				}
				if(ids.indexOf(',') != -1) {
					ids = ids.substring(0, ids.length - 1);
					axios({
						method: 'post',
						data: {
							'param1': localStorage.getItem('stationId'),
							'param3': ids
						},
						url: '/emind/config/equipment/list/tree/data'
					}).then(function(d) { //
						if(!d){
							this_.$message.error('登录超时，请重新登录', 2, function() {});
							return;
						}
						if(d.data.code === '0') {
							for(var i = 0; i < d.data.data.list.length; i++) {
								this_.setstate(d.data.data.list[i]);
								$('#power' + d.data.data.list[i].id).html(d.data.data.list[i].power / 10 + 'kW');
								$('#voltage' + d.data.data.list[i].id).html(d.data.data.list[i].voltage / 10 + 'V');
								$('#current' + d.data.data.list[i].id).html(d.data.data.list[i].current / 10 + 'A');
								$('#soc' + d.data.data.list[i].id).html(parseFloat(d.data.data.list[i].soc / 10) + '%');
//								if(d.data.data.list[i].capacity == 0){
//									$('#soc' + d.data.data.list[i].id).html(parseFloat(d.data.data.list[i].soc / 10) + '%');
//								} else {
//									$('#soc' + d.data.data.list[i].id).html(parseFloat(d.data.data.list[i].batterySystemRemainingPower / d.data.data.list[i].capacity * 10).toFixed(2) + '%');
//								}
								if(d.data.data.list[i].type == 3){
									if(d.data.data.list[i].state === -1) {
										$('#state' + d.data.data.list[i].id).html('离线');
										$('#state' + d.data.data.list[i].id).css('color', '#B7B8BC');
									} else if(d.data.data.list[i].state === 0) {
										$('#state' + d.data.data.list[i].id).html('停机');
										$('#state' + d.data.data.list[i].id).css('color', '#ff2100');
									} else if(d.data.data.list[i].state === 1) {
										$('#state' + d.data.data.list[i].id).html('停机');
										$('#state' + d.data.data.list[i].id).css('color', '#ff2100');
									} else if(d.data.data.list[i].state === 2) {
										$('#state' + d.data.data.list[i].id).html('待机');
										$('#state' + d.data.data.list[i].id).css('color', '#409eff');
									} else if(d.data.data.list[i].state === 3) {
										$('#state' + d.data.data.list[i].id).html('充电');
										$('#state' + d.data.data.list[i].id).css('color', '#30cf00');
									} else if(d.data.data.list[i].state === 4) {
										$('#state' + d.data.data.list[i].id).html('放电');
										$('#state' + d.data.data.list[i].id).css('color', '#30cf00');
									} else if(d.data.data.list[i].state === 5) {
										$('#state' + d.data.data.list[i].id).html('降额');
										$('#state' + d.data.data.list[i].id).css('color', '#30cf00');
									} else if(d.data.data.list[i].state === 6) {
										if(d.data.data.list[i].power > 0){
											$('#state' + d.data.data.list[i].id).html('放电');
											$('#state' + d.data.data.list[i].id).css('color', '#30cf00');
										} else if(d.data.data.list[i].power == 0){
											$('#state' + d.data.data.list[i].id).html('待机');
											$('#state' + d.data.data.list[i].id).css('color', '#409eff');
										} else if(d.data.data.list[i].power < 0){
											$('#state' + d.data.data.list[i].id).html('充电');
											$('#state' + d.data.data.list[i].id).css('color', '#30cf00');
										} else {
											$('#state' + d.data.data.list[i].id).html('未知');
											$('#state' + d.data.data.list[i].id).css('color', '#B7B8BC');
										}
									} else if(d.data.data.list[i].state === 7) {
										$('#state' + d.data.data.list[i].id).html('故障');
										$('#state' + d.data.data.list[i].id).css('color', '#30cf00');
									} else {
										$('#state' + d.data.data.list[i].id).html('离线');
										$('#state' + d.data.data.list[i].id).css('color', '#B7B8BC');
									}
								} else if(d.data.data.list[i].type == 2){
									if(d.data.data.list[i].power > 0){
										$('#state' + d.data.data.list[i].id).html('放电');
										$('#state' + d.data.data.list[i].id).css('color', '#30cf00');
									} else if(d.data.data.list[i].power == 0){
										if(d.data.data.list[i].state == 1){
											$('#state' + d.data.data.list[i].id).html('待机');
											$('#state' + d.data.data.list[i].id).css('color', '#409eff');
										} else {
											$('#state' + d.data.data.list[i].id).html('离线');
											$('#state' + d.data.data.list[i].id).css('color', '#B7B8BC');	
										}
										
									} else if(d.data.data.list[i].power < 0){
										$('#state' + d.data.data.list[i].id).html('充电');
										$('#state' + d.data.data.list[i].id).css('color', '#30cf00');
//									} else {
//										$('#state' + d.data.data.list[i].id).html('离线');
//										$('#state' + d.data.data.list[i].id).css('color', '#B7B8BC');
									}
								}
							}
						} else {
							this_.$mssage.error('请求出错', 2, function() {});
						}
					}).catch(function(error) {
						if(error.message != '') {
							this_.$message.error(error.message, 2, function() {});
							return;
						}
					});
				}
			},
			reflush(flag){
				var this_ = this;
				var i = 0;
				var ff = false;
				$('#eLinks').css('width', this_.ds.children.length * 190 + 'px');
				if(flag){
					if(localStorage.getItem('eLinkId') == null) {
						if(this_.ds.children[0]){
							this_.ds.children[0].open = true;
							this_.data = this_.ds.children[0].children;
							this_.expandId = parseInt(this_.ds.children[0].id, 10);
							this_.expandNode = this_.ds.children[0];
							localStorage.setItem('eLinkId', this_.expandId)
						}
					} else {
						for(; i < this_.ds.children.length; i++) {
							if(this_.ds.children[i].id == localStorage.getItem('eLinkId')) {
								ff = true;
								if(this_.ds.children[i]){
									this_.ds.children[i].open = true;
									this_.data = this_.ds.children[i].children;
									this_.expandNode = this_.ds.children[i];
									this_.expandId = parseInt(this_.ds.children[i].id, 10);
									break;
								}
							}
						}
					}
				}
				if(!ff){
					if(this_.ds.children[0]){
						this_.ds.children[0].open = true;
						this_.data = this_.ds.children[0].children;
						this_.expandId = parseInt(this_.ds.children[0].id, 10);
						this_.expandNode = this_.ds.children[0];
						localStorage.setItem('eLinkId', this_.expandId)
					}
				}
				var windowWidth = $("#root").css("width").replace("px", "") * 1;
				var leafsWidth = this_.data.length * 190;
				if(leafsWidth > windowWidth){
					if(this_.expand){
						$('#leafs').css('width', (this_.data.length) * 190 + 'px');
						$('#leafs').css('margin-left', 0 + 'px');
						$('#out').css('overflow-x', 'scroll');
					} else {
						$('#out').css('overflow', 'hidden');
					}
				} else {
					var left = (windowWidth - this_.ds.children.length * 190) / 2 + i * 70 + 20;
					if(i > 0) {
						left += 50;
					}
					$('#leafs').css('width', (this_.data.length) * 190 + 'px');
					if((left - leafsWidth / 2 + 50) < 10) {
						$('#leafs').css('margin-left', (windowWidth - leafsWidth) / 2 + 'px');
					} else {
						if(i >= (this_.ds.children.length / 2)) {
							if(left * 2 > leafsWidth) {
								$('#leafs').css('margin-left', (windowWidth - leafsWidth) / 2 + 75 + 'px');
							} else {
                $('#leafs').css('margin-left', 10 + 'px');
							}
						} else {
 							if(leafsWidth / 2 + left < windowWidth) {
								$('#leafs').css('margin-left', left - leafsWidth / 2 + 75 + 'px');
							} else {
								$('#leafs').css('margin-left', windowWidth - leafsWidth + 10 + 'px');
							}
						}
						if((leafsWidth + ($('#leafs').css('margin-left').replace("px", "") * 1)) > windowWidth) {
							$('#leafs').css('margin-left', (windowWidth - leafsWidth - 10) + 'px');
						}
					}
				}
			},
			getEquipmentTreeFn() {
				var this_ = this;
				axios({
					method: 'get',
					url: '/emind/config/equipment/list/1/' + localStorage.getItem('stationId')
				}).then(function(d) { //
					if(!d){
						this_.$message.error('登录超时，请重新登录', 2, function() {});
						return;
					}
					this_.data = [];
					this_.ds.children = [];
					var i = 0;
					if(d.data.code === '0') {
						this_.ds.children = [];
						for(var i = 0; i < d.data.data.length; i++) {
							if(d.data.data[i].ntype == 1 && d.data.data[i] != null && d.data.data[i].children != null) {
								for(var j = 0; j < d.data.data[i].children.length; j++) {
									this_.ds.children.push(d.data.data[i].children[j]);
								}
							} else if(d.data.data[i].ntype == 2) {
								this_.ds.children.push(d.data.data[i]);
							}
						}
						this_.ds.children.sort(this_.sortId);
						if(localStorage.getItem('eLinkId') == null){
							if(this_.ds.children.length > 0){
								localStorage.setItem('eLinkId', this_.ds.children[0].id);
							}
						}
						this_.reflush(true);
						this_.expand = true;
					} else {
						this_.$message.error('请求出错', 2, function() {});
					}
					if(this_.timer_4 == null) {
						this_.reflushData();
						this_.timer_4 = window.setInterval(function() {
							this_.reflushData();
						}, 3000);
					}
					this_.data.sort(this_.sortId);
				}).catch(function(error) {
					if(error.message != '') {
						this_.$message.error(error.message, 2, function() {});
						return;
					}
				});
			},
			sortId(a,b){  
				return (a.num-b.num) * 1;  
		    },
			toDetial(e, param, action) {
				var this_ = this;
				if(param.ntype == 3) {
					localStorage.setItem('eblockId', parseInt(param.id, 10));
					if(action == 1){
						this_.$router.push({
							path: 'eblock'
						}).catch(param => {})
						var localBreadcrumbs = localStorage.getItem("localBreadcrumbs");
						var breadcrumbs = JSON.parse(localBreadcrumbs, function (key, value) {         
			 				return key.indexOf('date') >= 0 ? new Date(value) : value;    
						});
						if(breadcrumbs.length > 1){
							breadcrumbs.splice(1,1);
						}
						if(this_.expandNode != null){
							breadcrumbs.push(
								{
									name: this_.expandNode.name,
									url: "/energy_chain"
								}
							);
						}
						breadcrumbs.push(
							{
								name: param.name,
								url: ""
							}
						);
						this_.$store.commit("setLocalBreadcrumbs", breadcrumbs, {
							root : true
						});
					} else {
						this_.eblock = param.name
						this_.eblockSeterShow = true;
					}
					
				} else if(param.ntype == 2) {
					localStorage.setItem('eLinkId', parseInt(param.id, 10));
					if(action == 2){
						this_.$router.push({
							path: 'device-settings'
						}).catch(param => {})
						var localBreadcrumbs = localStorage.getItem("localBreadcrumbs");
						var breadcrumbs = JSON.parse(localBreadcrumbs, function (key, value) {         
			 				return key.indexOf('date') >= 0 ? new Date(value) : value;    
						});
						if(breadcrumbs.length > 1){
							breadcrumbs.splice(1,1);
						}
						breadcrumbs.push(
							{
								name: param.name,
								url: "/energy_chain"
							}
						);
						breadcrumbs.push(
							{
								name: "策略设置",
								url: ""
							}
						);
						this_.$store.commit("setLocalBreadcrumbs", breadcrumbs, {
							root : true
						});
					} else {
						this_.eLink = this.expandNode;
						this_.showDrawer();
					}
				} else {
					var localBreadcrumbs = localStorage.getItem("localBreadcrumbs");
						var breadcrumbs = JSON.parse(localBreadcrumbs, function (key, value) {         
			 				return key.indexOf('date') >= 0 ? new Date(value) : value;    
						});
						if(breadcrumbs.length > 1){
							breadcrumbs.splice(1,1);
						}
						if(this_.expandNode != null){
							breadcrumbs.push(
							{
								name: "储能电站",
								url: "/energy_chain"
							}
							);
						}
						breadcrumbs.push(
							{
								name: "电表数据",
								url: ""
							}
						);
						this_.$store.commit("setLocalBreadcrumbs", breadcrumbs, {
							root : true
						});
					this_.$router.push({
						path: 'meter'
					}).catch(param => {})
				}
			},
			handNode(e, param) {
				var this_ = this;
				this_.expandNode = param;
				this_.data = param.children;
				this_.data.sort(this_.sortId);
				var siderWidth = $('.sider')[0].offsetWidth;
				var left = e.currentTarget.getBoundingClientRect().left - siderWidth + $(window).scrollLeft();
				var windowWidth = $("#root").width();
   			var leafsWidth = this_.data.length * 190;
        console.log(leafsWidth);
        console.log(this.expand);
				if(this_.expand) {
					if(this_.expandId == param.id) {
						for(var i = 0; i < this_.ds.children.length; i++) {
							if(this_.ds.children[i].id === '' + param.id) {
								this_.ds.children[i].open = false;
							}
						}
						this_.expandId = -1;
						localStorage.setItem('eLinkId', this_.expandId);
						this_.expand = false;
						param.open = false;
					} else {
						for(var i = 0; i < this_.ds.children.length; i++) {
							if(this_.ds.children[i].id === '' + this_.expandId) {
								this_.ds.children[i].open = false;
							}
						}
						this_.expandId = param.id;
						localStorage.setItem('eLinkId', this_.expandId);
						this_.expand = true;
						param.open = true;
						this_.reflushData();
					}
				} else {
					this_.expandId = param.id;
					localStorage.setItem('eLinkId', this_.expandId);
					for(var i = 0; i < this_.ds.children.length; i++) {
						if(this_.ds.children[i].id === '' + param.id) {
							this_.ds.children[i].open = true;
						}
					}
					this_.expand = true;
					this_.reflushData();
				}
				if(!this_.expand){
					this_.expandNode = null;
				}
			}
		}
	}
</script>

<style>
@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";
.control{
	height: 35px;
	padding: 5px 10px;
	font-family: PingFangSC-Regular;
	font-size: 13px;
	color: #E2E2E2;
	font-weight: 400;
}
.chain_station{
	padding-bottom: 10px;
	width: 170px;
	background: #30303C;
	border-radius: 16px;
	margin: auto;
}
.chain_title{
	float: left;
	height: 30px;
	padding: 10px;
	font-family: PingFangSC-Semibold;
	font-size: 16px;
	color: #E2E2E2;
	line-height: 27px;
	font-weight: 600;
}
.chain_img{
	padding: 0 26px;
}
.leaf_img{
	padding: 0 49px;
}
.chain_link{
	width: 170px;
	background: #30303C;
	border-radius: 16px;
	margin: auto;
}
.td_name{
	width: 42px;
	height: 18px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #B7B8BC;
	line-height: 18px;
	font-weight: 400;
}
.td_data{
	width: 44px;
	height: 18px;
	font-family: PingFangSC-Semibold;
	font-size: 14px;
	color: #E2E2E2;
	line-height: 18px;
	font-weight: 600;
}

#leafs {
	overflow-y: hidden;
	overflow-x: auto;
}

#eLinks {
	overflow-y: hidden;
	overflow-x: auto;
}

#roor {
	margin: 0 10px;
	float: left;
}

.root-node {
	background: #CCCCCC;
	cursor: pointer;
	width: 100px;
	padding: 10px 0;
	border: solid #E05E00 2px;
	margin: auto;
}

.leaf {
	background: #CCCCCC;
	cursor: pointer;
	width: 100px;
	padding: 10px 0;
	border: solid #E05E00 2px;
	margin: auto;
}

.node {
	width: 150px;
	height: 210px;
	background: #30303C;
	border-radius: 16px;
	margin: auto;
}

.vertical-line-up {
	width: 2px;
	background: #FF0000;
	height: 20px;
	margin: auto;
}

.vertical-line-down {
	width: 2px;
	background: #FF0000;
	height: 20px;
	margin: auto;
}

.chain_childrens {
	overflow: visible;
}

.float-left {
	float: left;
	margin: 0 10px;
}

.horizontal-line {
	overflow: hidden;
	border: solid #FF0000 1px;
	margin: 0 94px;
}
.elink-data{
	text-align: center;
	float: left;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #E2E2E2;
	line-height: 32px;
	font-weight: 400;
}
.elink_data_name{
	padding-left: 16px;
	float: left;
	width: 120px;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #B7B8BC;
	line-height: 32px;
	font-weight: 400;
}
</style>